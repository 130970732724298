<template>
  <div class="sales-by_activity-stats">
    <page-header
      :title="title"
      icon="fas fa-money-bill-1-wave"
      :links="getLinks()"
    >
    </page-header>
    <div>
      <b-row>
        <b-col cols="9" ref="printMe">
          <loading-gif :loading-name="statsLoading"></loading-gif>
          <div class="empty-text"  v-if="elements.length === 0">Aucune données correspondantes</div>
          <div v-show="!isLoading(statsLoading)" ref="excelMe">
            <table class="table table-bordered">
              <tr v-if="elements.length">
                <td class="header-cell cell-odd"></td>
                <td
                  v-for="year of displayedSchoolYears" :key="year.id"
                  class="text-center header-cell"
                  colspan="2"
                >
                  {{ year.name }}
                </td>
              </tr>
              <tr v-if="elements.length">
                <td class="header-cell cell-odd"></td>
                <td
                  v-for="column of columns" :key="column.id"
                  class="text-center header-cell"
                  :class="cellClas(column)"
                >
                  {{ column.name }}
                </td>
              </tr>
              <tr v-if="elements.length && !showEvents">
                <td class="header-cell">Total</td>
                <td
                  v-for="column of columns" :key="column.id"
                  class="text-center header-cell"
                  :class="cellClas(column)"
                >
                  <span v-if="column.sales">{{ headerSum(column.year) }}</span>
                  <span v-else>{{ discountSum(column.year) }}</span>
                  <div v-if="diff" class="ignore">
                    <div class="diff">{{ getTotalDiff(column) }}</div>
                  </div>
                </td>
              </tr>
              <tr v-for="(element, rowIndex) of elements" :key="element">
                <td class="text-left cell-odd">
                  {{ element }}
                </td>
                <td
                  v-for="cell of getElementRow(element)"
                  :key="cell.index"
                  class="number text-center"
                  :class="cellClas(cell, rowIndex)"
                >
                  {{ getCellValue(cell) }}
                  <div v-if="diff && getCellValue(cell) && getDiff(element, cell)" class="ignore">
                    <div class="diff">{{ getDiff(element, cell) }}</div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </b-col>
        <b-col cols="3">
          <b-form-checkbox name="showPercentage" id="showPercentage" v-model="showPercentage">
            En pourcentage
          </b-form-checkbox>
          <br />
          <b-form-checkbox name="diff" id="diff" v-model="forceDiff" :disabled="selectedSchoolYears.length <= 1">
            Affichage évolution
          </b-form-checkbox>
          <br /><br />
          <active-school-years-multi-select v-model="selectedSchoolYears"></active-school-years-multi-select>
          <br /><br />
          <activities-by-select v-model="showBy" :include-events="!youth"></activities-by-select>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import ActiveSchoolYearsMultiSelect from '@/components/SchoolYears/ActiveSchoolYearsMultiSelect'
import ActivitiesBySelect from '@/components/Stats/ActivtiesBySelect'
import { currency } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi, openDocument } from '@/utils/http'
import { sum } from '@/utils/math'
import { compareNumbers, compareStrings } from '@/utils/sorting'
import store from '@/store'

export default {
  name: 'sales-by-activity',
  mixins: [BackendMixin],
  components: {
    ActiveSchoolYearsMultiSelect,
    ActivitiesBySelect,
    LoadingGif,
    PageHeader,
  },
  props: {
    youth: [String, Number],
  },
  data() {
    return {
      statsLoading: 'sales-by-activity',
      selectedSchoolYears: [],
      statsByYear: new Map(),
      elementsMap: new Map(),
      rows: [],
      sums: [],
      discounts: [],
      showPercentage: false,
      forceDiff: true,
      showBy: null,
      events: false,
    }
  },
  computed: {
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    title() {
      if (this.youth) {
        return 'Ventes des inscriptions ' + this.youthHomeLabel
      } else {
        return this.showEvents ? 'Ventes par événements' : 'Ventes par activités'
      }
    },
    diff() {
      return this.selectedSchoolYears.length > 1 && this.forceDiff
    },
    displayedSchoolYears() {
      const schoolYears = [].concat(this.selectedSchoolYears)
      return schoolYears.sort((a, b) => compareNumbers(a.startYear, b.startYear))
    },
    columns() {
      const columns = []
      for (const year of this.displayedSchoolYears) {
        columns.push({ index: columns.length, name: 'Ventes', year: year, sales: true, })
        columns.push({ index: columns.length, name: 'Réductions', year: year, sales: false, })
      }
      return columns
    },
    elements() {
      return Array.from(this.elementsMap.keys()).sort(
        (elt1, elt2) => {
          return compareStrings(elt1.toLowerCase(), elt2.toLowerCase())
        }
      )
    },
    showEvents() {
      return this.showBy && this.showBy.id > 20
    },
  },
  mounted() {
    this.onLoaded()
  },
  watch: {
    selectedSchoolYears: function() {
      this.onLoaded()
    },
    showBy: function() {
      this.onLoaded()
    },
    events: function() {
      this.onLoaded()
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async onLoaded() {
      await this.loadStats()
    },
    async loadStats() {
      if (this.showBy) {
        this.startLoading(this.loadingName)
        const statsByYear = new Map()
        for (const schoolYear of this.selectedSchoolYears) {
          let url = '/stats/api/sales-by-activities/' + this.youth + '/' + this.showBy.id + '/' + schoolYear.id + '/'
          const backendApi = new BackendApi('get', url)
          try {
            const resp = await backendApi.callApi()
            statsByYear.set(schoolYear.id, resp.data)
          } catch (err) {
            await this.addError(this.getErrorText(err))
          }
        }
        this.statsByYear = statsByYear
        this.buildRows()
        this.endLoading(this.loadingName)
      }
    },
    getLinks() {
      return [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: (this.isLoading(this.statsLoading)) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: (this.isLoading(this.statsLoading)) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = 'ventes-par-activite-' + moment().format('YYYY-MM-DD')
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelMe() {
      const diff = this.forceDiff
      this.forceDiff = false
      const that = this
      setTimeout(
        async function() {
          const docUrl = '/documents/table-to-excel/<key>/'
          const docSlug = 'ventes-par-activite-' + moment().format('YYYY-MM-DD')
          const docContent = that.$refs.excelMe.innerHTML.toString()
          try {
            await openDocument(docUrl, docSlug, docContent)
          } catch (err) {
            await that.addError(this.getErrorText(err))
          }
          that.forceDiff = diff
        },
        200
      )
    },
    getElementRow(elt) {
      const rowIndex = this.elementsMap.get(elt)
      let row = this.rows[rowIndex]
      const cells = []
      for (const elt of row) {
        cells.push({ value: elt.value, index: cells.length, sales: true, yearIndex: rowIndex, })
        cells.push({ value: elt.discount, index: cells.length, sales: false, yearIndex: rowIndex, })
      }
      return cells
    },
    buildRows() {
      const elementsMap = new Map()
      const rows = []
      const newRow = new Array(this.displayedSchoolYears.length).fill({ value: 0, discount: 0, })
      for (let yearIndex = 0; yearIndex < this.displayedSchoolYears.length; yearIndex++) {
        const year = this.displayedSchoolYears[yearIndex]
        const stats = this.statsByYear.get(year.id)
        if (stats) {
          for (const elt of stats) {
            let row
            const element = elt.name || ''
            if (!elementsMap.has(element)) {
              elementsMap.set(element, rows.length)
              row = [].concat(newRow)
              rows.push(row)
            } else {
              const rowIndex = elementsMap.get(element)
              row = rows[rowIndex]
            }
            row[yearIndex] = { value: elt.amount, discount: elt.discount, }
          }
        }
        this.sums[yearIndex] = sum(rows.map(row => row[yearIndex].value))
        this.discounts[yearIndex] = sum(rows.map(row => row[yearIndex].discount))
      }
      this.rows = rows
      this.elementsMap = elementsMap
    },
    yearClass(year) {
      const yearIndex = this.displayedSchoolYears.map(elt => elt.id).indexOf(year.id)
      return (yearIndex % 2) ? 'cell-odd' : ''
    },
    cellClas(cell, rowIndex) {
      return (cell.index % 2) ? 'cell-odd' : ''
    },
    headerSum(year) {
      const yearIndex = this.displayedSchoolYears.map(elt => elt.id).indexOf(year.id)
      return currency(this.sums[yearIndex])
    },
    discountSum(year) {
      const yearIndex = this.displayedSchoolYears.map(elt => elt.id).indexOf(year.id)
      return currency(this.discounts[yearIndex])
    },
    getCellValue(cell) {
      if (this.showPercentage) {
        const cellValue = cell.value
        const refValue = cell.sales ? this.sums[cell.index / 2] : this.discounts[(cell.index - 1) / 2]
        if (cellValue && refValue) {
          const value = Math.round(10000 * cellValue / refValue) / 100
          return '' + value + '%'
        }
        return ''
      }
      if (cell.value) {
        return currency(cell.value)
      } else {
        return ''
      }
    },
    getCellDiscount(cell) {
      if (this.showPercentage) {
        return ''
      }
      if (cell.discount) {
        return currency(cell.discount)
      } else {
        return cell.discount
      }
    },
    getTotalDiff(column) {
      const yearIndex = this.displayedSchoolYears.map(elt => elt.id).indexOf(column.year.id)
      if (yearIndex > 0) {
        const value = column.sales ? this.sums[yearIndex] : this.discounts[yearIndex]
        const prevValue = column.sales ? this.sums[yearIndex - 1] : this.discounts[yearIndex - 1]
        if (value && prevValue) {
          const diff = Math.round(10000 * (value - prevValue) / prevValue) / 100
          const prefix = (diff > 0) ? '+' : ''
          return prefix + diff + '%'
        }
      }
      return ''
    },
    getDiff(element, cell) {
      const row = this.getElementRow(element)
      if (cell.index > 1) {
        const prevValue = row[cell.index - 2].value
        if (cell.value && prevValue) {
          const diff = Math.round(10000 * (cell.value - prevValue) / prevValue) / 100
          const prefix = (diff > 0) ? '+' : ''
          return prefix + diff + '%'
        }
      }
      return ''
    },
  },
}
</script>

<style scoped lang="less">
.number {
  text-align: right;
}
tr td.header-cell {
  background: #444;
  color: #fff;
}
tr td.header-cell.cell-odd {
 background: #222;
}
tr:nth-of-type(odd) {
  background: #eee;
}
tr:nth-of-type(even) {
  background: #fff;
}
tr:nth-of-type(odd) .cell-odd {
  background: #ccc;
}
tr:nth-of-type(even) .cell-odd {
  background: #ddd;
}
.diff {
  font-size: 11px;
  background: #111;
  color: #fff;
  display: inline-block;
  padding: 2px 5px;
  border-radius: 8px;
}
.discount {
  font-size: 11px;
  background: #deb85f;
  color: #000;
  display: inline-block;
  padding: 5px;
  border-radius: 2px;
  vertical-align: center;
}
</style>
